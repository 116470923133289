
import { BaseService } from "../../BaseService";

export class LanuariusBaseService extends BaseService {

    constructor() {
        super(process.env.REACT_APP_LANUARIUS_BASE_API_URL ?? "http://127.0.0.1:9001");
    }

}

