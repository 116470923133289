import { ObjectHelper, TypeChecker } from "@ronuse/noseur";

export const Debugger = {

    SECURE_FIELDS: [
        "access_token",
        "session_token",
    ],

    sanitize: (...args: any) => {
        for (let index = 0; index < args.length; index++) {
            if (TypeChecker.isObject(args[index])) {
                args[index] = ObjectHelper.cloneObject(args[index]);
                Object.keys(args[index]).forEach((key) => {
                    if (Debugger.SECURE_FIELDS.includes(key)) {
                        args[index][key] = "<REDACTED>";
                        return;
                    }
                });
            }
        }
        return args;
    },

    log: (...args: any) => console.log("RIVTN.PATULCIUS", ...Debugger.sanitize(...args)),
    warn: (...args: any) => console.warn("RIVTN.PATULCIUS", ...Debugger.sanitize(...args)),
    error: (...args: any) => console.error("RIVTN.PATULCIUS", ...Debugger.sanitize(...args)),

}
