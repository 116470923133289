
import React from "react";
import Compound from "../Compound";
import { Debugger, Schemes, Utils } from "../../utils";
import { i18nManager } from "../../i18n";
import { NavLink } from "react-router-dom";
import * as ReactRouterDom from "react-router-dom";
import UserPlaceholder from "../../assets/images/user_placeholder.png";
import { Button, ButtonManageRef, Checkbox, NoseurObject, ObjectHelper } from "@ronuse/noseur";
import { IProfile, IBusiness } from "../../services";

function Authorize() {
    const labels = i18nManager.Labels.oauth;
    const navigate = ReactRouterDom.useNavigate();
    const [success, setSuccess] = React.useState<boolean>();
    const { state } = ReactRouterDom.useLocation() as { state: { referrer: string; profile: IProfile; business: IBusiness; permissions: NoseurObject<string>[]; } };

    React.useEffect(() => {
        Debugger.log("Authorize:useEffect", state);
        if (!(state?.referrer && state?.profile && state?.profile && state?.permissions)) {
            navigate(-1);
            return;
        }
    }, []);

    return Compound({
        isOauth: true,
        business: state?.business,
        secondaryFormElement: renderAccountForm(),
    });

    function renderAccountForm() {
        return (<div className="authorize">
            <div className="images">
                <img alt={state?.business.name} src={state?.business.logo_location} />
                <img alt={state?.profile.email} src={state?.profile.profile_picture_location} />
            </div>
            <span className="ask" dangerouslySetInnerHTML={{ __html: labels.authorize_business.replace("${business_name}", state?.business.name) }}></span>
            <div className="permissions">
                <span className="title">{labels.business_will_be_able_to}:</span>
                {state?.permissions?.map((permission) => (<div className="permission">
                    {Utils.CIRCLE}
                    <span className="label">{permission.label}</span>
                    <i className="fa fa-info tt-toggle" />
                    <span className="tooltip">{permission.info}</span>
                </div>))}
            </div>
            <div className="controls">
                <Button scheme={Schemes.RIVTN_PATULCIUS} text="Authorise" raised />
                <Button scheme={Schemes.RIVTN_PATULCIUS} text="Deny" textOnly />
            </div>
        </div>);
    }

}

export default Authorize;
