
import React from "react";
import Compound from "../Compound";
import { i18nManager } from "../../i18n";
import * as ReactRouterDom from "react-router-dom";
import { Debugger, Schemes, Utils } from "../../utils";
import { AuthService, SanitizedResponse } from "../../services";
import { BoolHelper, ButtonManageRef, FormControl, FormGroup, NoseurObject, ObjectHelper, TextInput, alertDialog } from "@ronuse/noseur";

const faFormValues: NoseurObject<any> = {};
const resetAuthenticatorFormValues: NoseurObject<any> = {};

export enum FactorState {

    EMAIL_FACTOR = "email_factor",
    PHONE_FACTOR = "phone_factor",
    MULTI_FACTOR = "multi_factor",
    AUTHENTICATOR = "authenticator",
    SECURITY_QUESTIONS = "security_questions",

}

function MultiFactor() {
    const authService = AuthService.getInstance();
    const navigate = ReactRouterDom.useNavigate();
    const { state } = ReactRouterDom.useLocation();
    const [success, setSuccess] = React.useState<boolean>();
    const [processing, setProcessing] = React.useState<boolean>();
    const faCodeTimerElement = React.useRef<HTMLSpanElement>(null);
    const faRequestCodeElement = React.useRef<HTMLSpanElement>(null);
    let [faCodesTimer, setFaCodesTimer] = React.useState<NodeJS.Timeout>();
    const [factorState, setFactorState] = React.useState<FactorState>(state?.factorState);
    const labels = i18nManager.Labels.multi_factor[factorState ?? FactorState.EMAIL_FACTOR];

    React.useEffect(() => {
        Debugger.log("MultiFactor:useEffect", state, factorState);
        if (!state?.factorState) {
            navigate("/", { replace: true });
            return;
        }
        faFormValues["session_token"] = state.response.session_token;
        if (BoolHelper.equalsAny(factorState, [FactorState.PHONE_FACTOR, FactorState.EMAIL_FACTOR, FactorState.MULTI_FACTOR])) {
            requestFaCodes();
            labels.secondary_action = {
                ...labels.secondary_action,
                desc: (<span style={{ display: "none" }} ref={faCodeTimerElement}>05:00</span>),
                label: (<span ref={faRequestCodeElement} onClick={requestFaCodes}>{i18nManager.Labels.multi_factor.sending_authorization_codes}</span>),
            } as any;
        } else if (factorState === FactorState.AUTHENTICATOR) {
            labels.secondary_action.onAction = resetActorAuthenticator;
        }
    }, []);

    return Compound({
        success,
        processing,
        desc: labels.desc,
        title: labels.title,
        secondaryAction: labels.secondary_action,
        actionLabel: i18nManager.Labels.common.continue,
        onInput: (_: string, _v: any) => setSuccess(undefined),
        form: (factorState !== FactorState.SECURITY_QUESTIONS ? labels.form : state.response?.required_fa?.security_questions?.map((question: string, index: number) => {
            return {
                name: question,
                title: question,
                icon: "fa fa-circle",
                placeholder: `${i18nManager.Labels.common.answer} ${index + 1}`
            };
        })),
        onAction: (buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>, onDone?: () => void) => {
            Debugger.log("MultiFactor:onAction", factorState);
            if (BoolHelper.equalsAny(factorState, [FactorState.MULTI_FACTOR, FactorState.EMAIL_FACTOR, FactorState.PHONE_FACTOR])) {
                if (BoolHelper.equalsAny(factorState, [FactorState.MULTI_FACTOR, FactorState.PHONE_FACTOR]) && formValues.phone_value) {
                    faFormValues["sms_factor"] = { ...faFormValues["sms_factor"], value: formValues["phone_value"] };
                }
                if (BoolHelper.equalsAny(factorState, [FactorState.MULTI_FACTOR, FactorState.EMAIL_FACTOR]) && formValues.email_value) {
                    faFormValues["email_factor"] = { ...faFormValues["email_factor"], value: formValues["email_value"] };
                }
                proceedToFaAuth(buttonManageRef, faFormValues, () => {
                    onDone && onDone();
                    delete faFormValues["sms_factor"];
                    delete faFormValues["email_factor"];
                    clearInterval(faCodesTimer); setFaCodesTimer(undefined);
                    if (state.response?.required_fa?.security_questions) {
                        setFactorState(FactorState.SECURITY_QUESTIONS);
                    } else if (state.response?.required_fa?.actor_authenticator) {
                        setFactorState(FactorState.AUTHENTICATOR);
                    }
                });
            } else if (factorState === FactorState.SECURITY_QUESTIONS) {
                faFormValues["security_answers"] = ObjectHelper.clone(formValues);
                proceedToFaAuth(buttonManageRef, faFormValues, () => {
                    onDone && onDone();
                    delete faFormValues["security_answers"];
                    if (state.response?.required_fa?.actor_authenticator) {
                        setFactorState(FactorState.AUTHENTICATOR);
                    }
                });
            } else if (factorState === FactorState.AUTHENTICATOR) {
                faFormValues["actor_code"] = formValues["actor_code"];
                proceedToFaAuth(buttonManageRef, faFormValues, () => {
                    onDone && onDone();
                    delete faFormValues["actor_code"];
                });
            }
        },
    });

    async function proceedToFaAuth(buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>, onSuccess: () => void) {
        Debugger.log("MultiFactor:proceedToFaAuth", formValues);
        authService.confirmSignInFa(formValues).then(({ sanitized }: SanitizedResponse<any>) => {
            Debugger.log("MultiFactor:proceedToFaAuth:success", sanitized);
            onSuccess();
            if (sanitized.access_token) {
                setProcessing(true);
                Utils.redirectToReferrer(state.redirectLocation, state.response.session_token, sanitized.access_token, state.isOauth);
            }
        }).catch((error: SanitizedResponse<any>) => {
            setSuccess(false);
            if (error?.errorMessage?.includes("security questions")) {
                error.errorMessage = i18nManager.Labels.multi_factor.incorrect_security_question;
            } else if (error?.errorMessage?.includes("match for the factor authentication") || error?.errorMessage?.includes("authorization code is invalid")) {
                error.errorMessage = i18nManager.Labels.multi_factor.factor_auth_failed;
            } else if (error?.errorMessage?.includes("Invalid session")) {
                navigate("/", { replace: true });
                return;
            }
            Utils.reportError(error);
        }).finally(() => {
            buttonManageRef?.setLoadingState(false);
        });
    }

    async function requestFaCodes() {
        const payload: NoseurObject<any> = {
            session_token: state.response.session_token,
        };
        setProcessing(true);
        clearInterval(faCodesTimer); setFaCodesTimer(undefined);
        if (faCodeTimerElement.current) {
            faCodeTimerElement.current.style.display = "none";
        }
        if (faRequestCodeElement.current) {
            faRequestCodeElement.current.style.pointerEvents = "none";
            faRequestCodeElement.current.innerText = `${i18nManager.Labels.multi_factor.sending_authorization_codes}`;
        }
        if (state.response?.required_fa?.phone) payload["send_phone_code"] = true;
        if (state.response?.required_fa?.email) payload["send_email_code"] = true;
        authService.requestSignInFaCodes(payload).then(({ sanitized }: SanitizedResponse<any>) => {
            if (sanitized.email_factor_id) {
                faFormValues["email_factor"] = {
                    factor_id: sanitized.email_factor_id
                };
            }
            if (sanitized.sms_factor_id) {
                faFormValues["sms_factor"] = {
                    factor_id: sanitized.sms_factor_id
                };
            }
            triggerFaCodesCountDown();
            if (faRequestCodeElement.current) {
                faRequestCodeElement.current.style.opacity = "0.5";
                faRequestCodeElement.current.style.pointerEvents = "none";
            }
            Debugger.log("MultiFactor:requestFaCodes", sanitized, faFormValues);
        }).catch((error: SanitizedResponse<any>) => {
            if (error?.errorMessage?.includes("Invalid session")) {
                navigate("/", { replace: true });
                return;
            }
            setSuccess(false);
            Utils.reportError(error);
            if (faRequestCodeElement.current) faRequestCodeElement.current.style.pointerEvents = "all";
        }).finally(() => {
            setProcessing(false);
            if (faRequestCodeElement.current) faRequestCodeElement.current.innerText = i18nManager.Labels.multi_factor.request_codes;
        });
    }

    async function triggerFaCodesCountDown() {
        if (!faCodeTimerElement.current) return;
        faCodeTimerElement.current.style.display = "inline-block";
        let fiveMinutesInSeconds = 300;
        faCodesTimer = setInterval(() => {
            fiveMinutesInSeconds--;
            const remnantBy60Seconds = fiveMinutesInSeconds / 60;
            if (!faCodeTimerElement.current) {
                clearInterval(faCodesTimer); faCodesTimer = undefined; setFaCodesTimer(faCodesTimer);
                return;
            }
            const min = Math.floor(remnantBy60Seconds);
            const secs = Math.round((((remnantBy60Seconds - min) * 60) / 100) * 100) / 100;
            faCodeTimerElement.current.innerText = `${min.toString().padStart(2, "0")}:${((secs.toString().split(".")[1] ?? "").padEnd(2, "0") ?? "").padStart(2, "0")}`;
            if (fiveMinutesInSeconds === 1) {
                faCodeTimerElement.current.innerText = "00:00";
                clearInterval(faCodesTimer); setFaCodesTimer(undefined);
                if (faRequestCodeElement.current) {
                    faRequestCodeElement.current.style.opacity = "1";
                    faRequestCodeElement.current.style.pointerEvents = "unset";
                }
            }
        }, 1000)
        setFaCodesTimer(faCodesTimer);
    }

    function resetActorAuthenticator() {
        const errorRef: any = { current: undefined };
        const formControlManageRefs: { [key: string]: any } = {};
        const confirmButton: React.ForwardedRef<ButtonManageRef> = { current: null };

        const alert = alertDialog({
            message: (<div className="reset-authenticator">
                <span className="title">{i18nManager.Labels.multi_factor.reset_actor_authenticator.title}</span>
                <span className="desc">{i18nManager.Labels.multi_factor.reset_actor_authenticator.desc}</span>
                <span className="auth-codes-controls">
                    <span className="timer" ref={faCodeTimerElement}></span>
                    <span className="link" ref={faRequestCodeElement} onClick={() => sendActorAuthenticatorResetCodes(errorRef)}>{i18nManager.Labels.multi_factor.reset_actor_authenticator.get_auth_codes}</span>
                </span>
                <FormGroup scheme={Schemes.RIVTN_PATULCIUS}>
                    {i18nManager.Labels.multi_factor.reset_actor_authenticator.form.map((formInput) => {
                        return (<FormControl key={formInput.name} label={formInput.title} leftContent={formInput.icon} manageRef={(e: any) => formControlManageRefs[formInput.name] = e} fill highlight>
                            <TextInput disabled={processing} key={formInput.name} defaultValue={formInput.value} name={formInput.name} placeholder={formInput.placeholder} onInput={(e: any) => {
                                errorRef.current.innerText = " ";
                                if (formInput.name === "phone_value") {
                                    resetAuthenticatorFormValues["sms_factor"] = {
                                        ...resetAuthenticatorFormValues["sms_factor"],
                                        value: e.target.value,
                                    };
                                } else if (formInput.name === "email_value") {
                                    resetAuthenticatorFormValues["email_factor"] = {
                                        ...resetAuthenticatorFormValues["email_factor"],
                                        value: e.target.value,
                                    };
                                }
                            }} required />
                        </FormControl>);
                    })}
                </FormGroup>
                <div ref={errorRef} className="error-label"> </div>
            </div>),
            cancel: {
                scheme: Schemes.RIVTN_PATULCIUS,
                label: i18nManager.Labels.common.close,
                props: { textOnly: true, style: { fontWeight: "bold" } },
            },
            confirm: {
                scheme: Schemes.RIVTN_PATULCIUS,
                label: i18nManager.Labels.common.continue,
                props: {
                    raised: true,
                    manageRef: confirmButton,
                    loadingProps: Utils.BUTTON_LOADING_PROPS,
                    style: { marginLeft: 30, padding: "10px 30px", minWidth: 100 },
                }
            },
            onConfirm: () => {
                errorRef.current.innerText = " ";
                Debugger.log("MultiFactor:resetActorAuthenticator:alertDialog:onConfirm", resetAuthenticatorFormValues)
                if (!resetAuthenticatorFormValues.email_factor?.factor_id) {
                    errorRef.current.innerText = i18nManager.Labels.multi_factor.reset_actor_authenticator.request_codes_before_continue;
                    return;
                } else if (!resetAuthenticatorFormValues.sms_factor?.value || !resetAuthenticatorFormValues.email_factor?.value) {
                    errorRef.current.innerText = i18nManager.Labels.multi_factor.reset_actor_authenticator.phone_email_codes_required;
                    return;
                }
                confirmButton.current?.setLoadingState(true);
                authService.sendActorAuthenticatorEmail(resetAuthenticatorFormValues).then(({ }: SanitizedResponse<any>) => {
                    alert.hide();
                    setSuccess(true);
                    Utils.reportSuccess(i18nManager.Labels.multi_factor.reset_actor_authenticator.reset_successful);
                }).catch((error: SanitizedResponse<any>) => {
                    if (error?.errorMessage?.includes("Invalid session")) {
                        navigate("/", { replace: true });
                        return;
                    }
                    errorRef.current.innerText = i18nManager.Labels.multi_factor.reset_actor_authenticator.phone_or_email_codes_invalid;
                }).finally(() => {
                    confirmButton.current?.setLoadingState(false);
                });
            }
        });
        alert.show();
    }

    async function sendActorAuthenticatorResetCodes(errorRef: any) {
        clearInterval(faCodesTimer); setFaCodesTimer(undefined);
        if (faCodeTimerElement.current) {
            faCodeTimerElement.current.style.display = "none";
        }
        if (faRequestCodeElement.current) {
            faRequestCodeElement.current.style.pointerEvents = "none";
            faRequestCodeElement.current.innerText = `${i18nManager.Labels.multi_factor.sending_authorization_codes}`;
        }
        resetAuthenticatorFormValues["session_token"] = state.response.session_token;
        authService.sendActorAuthenticatorCodes(state.response.session_token).then(({ sanitized }: SanitizedResponse<any>) => {
            if (sanitized.email_factor_id) {
                resetAuthenticatorFormValues["email_factor"] = {
                    factor_id: sanitized.email_factor_id
                };
            }
            if (sanitized.sms_factor_id) {
                resetAuthenticatorFormValues["sms_factor"] = {
                    factor_id: sanitized.sms_factor_id
                };
            }
            triggerFaCodesCountDown();
            if (faCodeTimerElement.current) faCodeTimerElement.current.innerText = "05:00";
            if (faRequestCodeElement.current) {
                faRequestCodeElement.current.style.opacity = "0.5";
                faRequestCodeElement.current.style.pointerEvents = "none";
            }
            errorRef.current.innerText = " ";
            Debugger.log("MultiFactor:sendActorAuthenticatorResetCodes", sanitized, resetAuthenticatorFormValues);
        }).catch((error: SanitizedResponse<any>) => {
            if (error?.errorMessage?.includes("Invalid session")) {
                navigate("/", { replace: true });
                return;
            }
            errorRef.current.innerText = error?.errorMessage;
            if (faRequestCodeElement.current) faRequestCodeElement.current.style.pointerEvents = "all";
        }).finally(() => {
            if (faRequestCodeElement.current) faRequestCodeElement.current.innerText = i18nManager.Labels.multi_factor.request_codes;
        });
    }

}

export default MultiFactor;
