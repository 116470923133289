
import { NoseurObject } from "@ronuse/noseur";
import { LanuariusBaseService } from "./LanuariusBaseService";

export class AuthService extends LanuariusBaseService {

    protected static instance: AuthService;

    private constructor() {
        super();
    }

    static getInstance() {
        if (!AuthService.instance) AuthService.instance = new AuthService();
        return AuthService.instance;
    }

    signup(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/user", payload));
    }

    signin(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/user/auth", payload));
    }

    refreshToken(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/user/auth/refresh", payload));
    }

    requestSignInFaCodes(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/user/auth/2fa/code", payload));
    }

    confirmSignInFa(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/user/auth/2fa", payload));
    }

    sendActorAuthenticatorCodes(sessionToken: string): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/account/actor/authenticator/reset/request", { session_token: sessionToken }));
    }

    sendActorAuthenticatorEmail(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/account/actor/authenticator/reset/email", payload));
    }

    sendPasswordResetEmail(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/account/password/reset/email", payload));
    }

    resetPassword(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/account/password/reset", payload));
    }

    getProfile(config?: any): Promise<any>  {
        return this.report(this.transport.get("/janus/lanuarius/api/v1/account", config ?? this.config));
    }

    requestEmailVerification(userId: string, prefix: string): Promise<any>  {
        return this.report(this.transport.get(`/janus/lanuarius/api/v1/account/verify/${userId}`, {
            ...this.config,
            params: {
                prefix,
            }
        }));
    }

    verifyEmail(payload: NoseurObject<any>): Promise<any>  {
        return this.report(this.transport.post("/janus/lanuarius/api/v1/account/verify", payload));
    }

}
