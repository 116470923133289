
import React from "react";
import Compound from "../Compound";
import { i18nManager } from "../../i18n";
import * as ReactRouterDom from "react-router-dom";
import { IBusiness, IProfile } from "../../services";
import { Classname, NoseurObject } from "@ronuse/noseur";
import { CacheManager, Debugger, Utils } from "../../utils";
import UserPlaceholder from "../../assets/images/user_placeholder.png";

function SelectAccount() {
    const labels = i18nManager.Labels.oauth;
    const navigate = ReactRouterDom.useNavigate();
    const [success, setSuccess] = React.useState<boolean>();
    const { state } = ReactRouterDom.useLocation() as { state: { isTrusted: boolean; referrer: string; profile: IProfile; business: IBusiness; permissions: NoseurObject<string>[]; } };

    React.useEffect(() => {
        Debugger.log("SelectAccount:useEffect", state);
        if (!(state?.referrer && state?.profile && state?.profile && state?.permissions)) {
            navigate(-1);
            return;
        }
    }, []);

    return Compound({
        success,
        isOauth: true,
        business: state?.business,
        secondaryFormElement: renderAccountForm(),
    });

    function renderAccountForm() {
        const oauthRedirectPayload: any = { ref: state.referrer };
        if (state.business?.external_id) oauthRedirectPayload.bid = state.business.external_id;
        return (<div className="select-account">
            <span className="title">{labels.choose_account} <span className="n">{Utils.collapseString(state.business.name, 50)}</span></span>
            <div className={Classname.build("accounts", (success ? "noseur-disabled" : null))}>
                <div className="account" onClick={() => {
                    if (state.isTrusted) {
                        setSuccess(true);
                        Utils.redirectToReferrer(state.referrer, CacheManager.get(CacheManager.SESSION_TOKEN_KEY), CacheManager.get(CacheManager.ACCESS_TOKEN_KEY), false);
                        return;
                    }
                    navigate("/oauth/authorize", {
                        state,
                    });
                }}>
                    <img alt={state?.profile.email} src={state.profile.profile_picture_location} />
                    <span className="name">{state.profile.first_name} {state.profile.last_name}</span>
                    <span className="email">
                        {Utils.collapseString(state.profile.email, 15)}
                        <span className="tooltip">{state.profile.email}</span>
                    </span>
                </div>
                <ReactRouterDom.NavLink className="account" to={{
                    pathname: "/",
                    search: ReactRouterDom.createSearchParams({ rloc: `/oauth?${ReactRouterDom.createSearchParams(oauthRedirectPayload).toString()}`, }).toString()
                }}>
                    <img alt="select account" src={UserPlaceholder} />
                    <span className="name">{labels.use_another_account}</span>
                </ReactRouterDom.NavLink>
            </div>
        </div>);
    }

}

export default SelectAccount;
