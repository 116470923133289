
import { Encryptor, LzEncryptor } from "./Encryptor";

export const CacheManager = {

    PROFILE_KEY: "rivtn.p",
    __cacheImpl__: localStorage,
    ACCESS_TOKEN_KEY: "rivtn.at",
    SESSION_TOKEN_KEY: "rivtn.st",
    __encryptorImpl__: LzEncryptor,

    setStorage(cacheImpl: Storage) {
        CacheManager.__cacheImpl__ = cacheImpl;
    },

    setEncryptor(encryptorImpl: Encryptor) {
        CacheManager.__encryptorImpl__ = encryptorImpl;
    },

    has(key: string) {
        const item = CacheManager.__cacheImpl__.getItem(key);
        return (item !== null && item !== "")
    },

    remove(key: string) {
        CacheManager.__cacheImpl__.removeItem(key);
    },

    insecurePut(key: string, value: string | number | Object) {
        const valueToStore = JSON.stringify({
            value,
            type: typeof value,
        });
        CacheManager.__cacheImpl__.setItem(key, CacheManager.__encryptorImpl__ ? CacheManager.__encryptorImpl__.encrypt(valueToStore) : valueToStore);
    },

    put(key: string, value: string | number | Object) {
        if (!CacheManager.has(CacheManager.ACCESS_TOKEN_KEY)) return;
        CacheManager.insecurePut(key, value);
    },

    get(key: string, fallback?: any) {
        if (!CacheManager.has(key)) {
            if (key === CacheManager.PROFILE_KEY) {
                window.location = "/" as any;
            }
            return fallback;
        }
        const cacheValue = CacheManager.__cacheImpl__.getItem(key) as string;
        const valueToStore = JSON.parse(CacheManager.__encryptorImpl__ ? CacheManager.__encryptorImpl__.decrypt(cacheValue) : cacheValue);
        return valueToStore.value;
    },

    clear() {
        CacheManager.__cacheImpl__.clear();
    }

}
