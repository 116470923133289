
import React from "react";
import { IBusiness } from "../services";
import { NavLink } from "react-router-dom";
import { FormInput, i18nManager } from "../i18n";
import { Debugger, Schemes, Utils } from "../utils";
import { Button, ButtonManageRef, Classname, ComposedPassword, Dropdown, FormControl, FormGroup, NoseurObject, ObjectHelper, ScrollPanel, TextInput } from "@ronuse/noseur";

export type CompoundProps = {
    desc?: string;
    title?: string;
    success?: boolean;
    isOauth?: boolean;
    form?: FormInput[];
    actionLabel?: string;
    business?: IBusiness;
    processing?: boolean;
    onInput?: (name: string, value: any) => void;
    secondaryFormElement?: React.ReactElement;
    onAction?: (buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>, onDone?: () => void) => void;
    secondaryAction?: {
        location?: string;
        onAction?: () => void;
        desc?: string | React.ReactElement;
        label: string | React.ReactElement;
    };
};

function Compound(props: CompoundProps) {
    const success = props.success;
    const labels = i18nManager.Labels;
    const processing = props.processing;
    const invalid = props.success == false;
    const actionButtonManageRef = React.useRef<ButtonManageRef>(null);
    const [formValues, setFormValues] = React.useState<NoseurObject<any>>(props.form?.reduce((acc: NoseurObject<any>, formInput) => {
        if (formInput.value) {
            acc[formInput.name] = formInput.value;
        }
        return acc;
    }, {}) as NoseurObject<any>);

    return (<div className="compound">
        <div className="rounda right"></div>
        <div className="rounda top-left"></div>
        <div className="rounda bottom-left"></div>
        {props.isOauth ? renderOAuthControls() : renderAuthControls()}
        <div className="footer">
            {labels.footer.map((footer) => (<a target="_blank" rel="referrer" href={footer.location}>{footer.label}</a>))}
        </div>
    </div>);

    function renderOAuthControls() {
        let splicedTosDetailTermsOfService = labels.oauth.tos_detail.split("${terms_of_service}");
        let splicedTosDetailPrivacyPolicy = splicedTosDetailTermsOfService[1].split("${privacy_policy}");
        return (<ScrollPanel className={Classname.build("compound-container oauth", { "error": invalid, "success": success, })} style={!props.business?.description ? { maxWidth: 500 } : undefined}>
            <div className={Classname.build("primary", (processing ? "processing" : null))} style={!props.business?.description ? { flex: 1, padding: 0 } : undefined}>
                <div className="header">
                    <span className="logo">{labels.rivtn}</span>
                    {props.business?.logo_location ? <img className="business-logo" alt={props.business?.name} src={props.business?.logo_location} /> : null}
                </div>
                <div className="panel">
                    {props.secondaryFormElement}
                </div>
                {props.business?.term_of_service ? (<div className="business-tos">
                    {ObjectHelper.expandStringTemplate(splicedTosDetailTermsOfService[0], props.business, { chop: "$" })}
                    <a target="_blank" href={props.business?.term_of_service}>{labels.oauth.terms_of_service}</a>
                    {splicedTosDetailPrivacyPolicy[0]}
                    <a target="_blank" href={props.business?.privacy_policy}>{labels.oauth.privacy_policy}</a>
                    {splicedTosDetailPrivacyPolicy[1]}
                </div>) : null}
                {renderLanguageDropdown()}
            </div>
            {!processing && props.business?.description ? (<div className="secondary">
                <img alt={props.business?.name} src={props.business?.logo_location} />
                <span className="title">{props.business?.name}</span>
                <span className="tag">{props.business?.tag_line}</span>
                <p className="content">{props.business?.description}</p>
            </div>) : null}
        </ScrollPanel>);
    }

    function renderAuthControls() {
        return (<ScrollPanel className={Classname.build("compound-container", { "error": invalid, "success": success, })}>
            <span className="logo">{labels.rivtn}</span>
            <span className="title">{props.title}</span>
            <span className="desc">{props.desc}</span>
            <FormGroup className="form" scheme={Schemes.RIVTN_PATULCIUS} action="" method="">
                {props.form!.map((formInput) => {
                    const onInput = (e: any) => {
                        const newFormValues = { ...formValues, };
                        newFormValues[formInput.name] = e.target.value;
                        setFormValues(newFormValues);
                        props.onInput && props.onInput(formInput.name, e.target.value);
                    }
                    if (formInput.type === "password") {
                        return (<ComposedPassword name={formInput.name} onInput={onInput} key={formInput.name} strengthIndicator={formInput.strengthy} progressProps={Utils.COMPOSED_PASSWORD_PROGRESS_PROPS}
                            inputProps={{ key: formInput.name + "-input", disabled: processing, defaultValue: formInput.value, required: true, placeholder: formInput.placeholder }}
                            formControlProps={{ label: formInput.title, invalid: invalid ?? formInput.invalid, leftContent: "fas fa-key", fill: true, highlight: true }} toggleMask />)
                    }
                    return (<FormControl key={formInput.name} label={formInput.title} leftContent={formInput.icon} fill highlight invalid={invalid ?? formInput.invalid}>
                        <TextInput disabled={processing} key={formInput.name + "-input"} defaultValue={formInput.value} name={formInput.name} placeholder={formInput.placeholder} onInput={onInput} required />
                    </FormControl>);
                })}
                {props.secondaryFormElement}
                {props.actionLabel
                    ? (<Button disabled={processing} scheme={Schemes.RIVTN_PATULCIUS} text={props.actionLabel} loadingProps={Utils.BUTTON_LOADING_PROPS}
                        manageRef={actionButtonManageRef} onClick={onAction} raised />)
                    : null}
            </FormGroup>
            {props.secondaryAction ? (
                <div className="secondary-actions">
                    <span>{props.secondaryAction.desc}</span>&nbsp;{props.secondaryAction.location
                        ? (<NavLink to={{ pathname: props.secondaryAction.location, search: window.location.search }}>{props.secondaryAction.label}</NavLink>)
                        : (<span className="link" onClick={() => props.secondaryAction?.onAction!()}>{props.secondaryAction.label}</span>)}
                </div>
            ) : null}
            {renderLanguageDropdown()}
        </ScrollPanel>);
    }

    function renderLanguageDropdown() {
        return (<Dropdown scheme={Schemes.RIVTN_PATULCIUS} options={i18nManager.Languages}
            selectedOptionIndex={Math.max(i18nManager.Languages.findIndex((value) => value.label === i18nManager.ActiveLanguage) ?? -1, 0)}
            borderless />);
    }

    function onAction() {
        Debugger.log("Compound:onAction", formValues);
        if (Object.keys(formValues).length !== props.form!.length) return;
        actionButtonManageRef.current?.setLoadingState(true);
        props.onAction!(actionButtonManageRef.current, formValues, () => setFormValues({}));
    }
}

export default Compound;
