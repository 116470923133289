
import ffs, { FFS } from "kyofuuc";
import { CacheManager, LzEncryptor } from "../utils";
import { AlertInterface, NoseurObject, TypeChecker } from "@ronuse/noseur";

export interface IProfile {
    email: string;
    last_name: string;
    first_name: string;
    other_names: string;
    external_id: string;
    profile_picture_location: string;
}

export interface IBusiness {
    name: string;
    tag_line: string;
    external_id: string;
    description: string;
    logo_location: string;
    privacy_policy?: string;
    term_of_service?: string;
}

export interface ApiResponseData<T> {
    data?: T;
    content?: T[];
    size?: number;
    transformed?: any;
    total_pages?: number;
    total_elements?: number;
    number_of_elements?: number;
    pageable?: {
        paged: boolean;
        offset: number;
        page_size: number;
        page_number: number;
    };
};

export interface ApiResponse<T> {
    message: string;
    success: boolean;
    data?: ApiResponseData<T>;
    secondary_data: NoseurObject<any>;
};

export type SanitizedResponse<T> = {
    sanitized: T;
    status: number;
    errors?: any[];
    errorMessage: string;
    apiResponse: ApiResponse<T>;
};

export class BaseService {

    // remove /json route after add formData submission on kyofuuc, and remove the headers this.config
    config: any = {
        headers: {
            Authorization: this.getAccessToken()
        }
    };
    transport: FFS;
    baseUrl: string = process.env.REACT_APP_LANUARIUS_BASE_API_URL ?? "http://127.0.0.1:9001";
    cacheManager = new (ffs as any).cachemanagers.LocalStorageFFSCacheManager({
        bucket: sessionStorage,
        encryptor: LzEncryptor.encrypt,
        decryptor: LzEncryptor.decrypt,
    });

    constructor(baseUrl?: string) {
        this.transport = ffs.init({
            httpConfig: {
                baseURL: baseUrl ?? this.baseUrl,
                ...this.config
            }
        } as any);
    }

    getAccessToken() {
        const accessToken = CacheManager.get(CacheManager.ACCESS_TOKEN_KEY);
        if (!accessToken) return null;
        return `Bearer ${accessToken}`;
    }

    normalizeParams(params: NoseurObject<any>) {
        if (!params.page) params.page = 1;
        if (!params.size) params.size = 10;
        if (!params.sort) params.sort = "id,DESC";
        Object.keys(params).forEach((key: string) => {
            const value = params[key];
            if (!TypeChecker.isArray(value)) return;
            params[key] = value.reduce((acc: string, v: string, index: number) => {
                if (index > 0) return `${acc},${key}=${v}`;
                return `${v}`;
            }, "");
        });
        return params;
    }

    report<T>(promise: Promise<any>, postResolve?: Function, postReject?: Function): Promise<SanitizedResponse<any>> {
        return new Promise((resolve, reject) => {
            promise.then((response: T) => {
                let transformedRespomse = this.buildResponse(response);
                if (postResolve) postResolve(transformedRespomse);
                resolve(transformedRespomse);
            }).catch((error: any) => {
                let transformedError = this.buildError(error);
                if (postReject) postReject(transformedError);
                reject(transformedError);
            });
        });
    }

    buildResponse<T>(response: any): SanitizedResponse<T> {
        if (!response.data || !response.data.data) return response;
        response.status = response?.status;
        response.apiResponse = response.data;
        response.sanitized = response.apiResponse.data;
        return response;
    }

    buildError<T>(error: any): SanitizedResponse<T> {
        if (!error) error = {};
        error.message = error?.response?.data?.message ?? error?.response?.message ?? error?.message ?? "An error occur please try again later";
        error.errors = error?.response?.data?.errors;
        error.errorMessage = error.message;
        error.status = error.response?.status ?? -1;
        return error;
    }

    backToLogin(dialog?: AlertInterface) {
        dialog?.destroy();
        CacheManager.clear();
        window.location = "/" as any;
    }

}
