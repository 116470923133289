
import React from "react";
import Compound from "../Compound";
import { i18nManager } from "../../i18n";
import { Debugger, Utils } from "../../utils";
import * as ReactRouterDom from "react-router-dom";
import { ButtonManageRef, NoseurObject } from "@ronuse/noseur";
import { AuthService, SanitizedResponse } from "../../services";

function ResetPassword() {
    const navigate = ReactRouterDom.useNavigate();
    const authService = AuthService.getInstance();
    const labels = i18nManager.Labels.reset_password;
    const [success, setSuccess] = React.useState<boolean>();
    const [searchParams, _] = ReactRouterDom.useSearchParams(window.location.search);

    React.useEffect(() => {
        if (!(searchParams.has("e") && searchParams.has("f") && searchParams.has("c"))) {
            navigate("/", { replace: true });
            return;
        }
    }, []);

    return Compound({
        success,
        desc: buildDesc(),
        title: labels.title,
        actionLabel: buildActionLabel(),
        form: (!success ? labels.form : []),
        onInput: (_: string, _v: any) => setSuccess(undefined),
        onAction: (buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>, onDone?: () => void) => {
            if (success) {
                navigate("/");
                return;
            }
            if (formValues.password !== formValues.confirm_password) {
                setSuccess(false);
                buttonManageRef?.setLoadingState(false);
                Utils.reportError(labels.passwords_must_match);
                return;
            }
            const payload = {
                email: searchParams.get("e"),
                password: formValues.password,
                email_factor: {
                    value: searchParams.get("c"),
                    factor_id: searchParams.get("f"),
                }
            };
            Debugger.log("ResetPassword:onAction", payload);
            authService.resetPassword(payload).then(({ }: SanitizedResponse<any>) => {
                setSuccess(true);
                onDone && onDone();
            }).catch((error: SanitizedResponse<any>) => {
                setSuccess(false);
                Utils.reportError(error);
            }).finally(() => {
                buttonManageRef?.setLoadingState(false);
            });
        },
    });

    function buildDesc() {
        if (success) {
            return labels.reset_successful;
        }
        return labels.desc;
    }

    function buildActionLabel() {
        if (success) {
            return labels.continue_to_sign_in;
        }
        return labels.action_label;
    }

}

export default ResetPassword;
