
import ReactDOM from 'react-dom/client';
import Oauth from './components/oauth/Oauth';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import Authorize from './components/oauth/Authorize';
import VerifyEmail from './components/auth/VerifyEmail';
import MultiFactor from './components/auth/MultiFactor';
import ResetPassword from './components/auth/ResetPassword';
import SelectAccount from './components/oauth/SelectAccount';
import ForgotPassword from './components/auth/ForgotPassword';
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import './assets/css/index.css';

const router = createBrowserRouter([
    { path: "*", element: <SignIn /> },
    { path: "/oauth", element: <Oauth /> },
    { path: "/sign-up", element: <SignUp /> },
    { path: "/fa", element: <MultiFactor /> },
    { path: "/verify-email", element: <VerifyEmail /> },
    { path: "/oauth/authorize", element: <Authorize /> },
    { path: "/reset-password", element: <ResetPassword /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/oauth/select-account", element: <SelectAccount /> },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    //<React.StrictMode>
    <RouterProvider router={router} />
    //</React.StrictMode>
);

