
import React from "react";
import Compound from "../Compound";
import { i18nManager } from "../../i18n";
import * as ReactRouterDom from "react-router-dom";
import { Debugger, Schemes, Utils } from "../../utils";
import { AuthService, SanitizedResponse } from "../../services";
import { Button, ButtonManageRef, NoseurObject } from "@ronuse/noseur";

function SignUp() {
    const labels = i18nManager.Labels.sign_up;
    const navigate = ReactRouterDom.useNavigate();
    const authService = AuthService.getInstance();
    const [success, setSuccess] = React.useState<boolean>();
    const [state, setState] = React.useState<"CONTINUE_TO_OAUTH" | "CONTINUE_TO_SIGN_IN">();

    return Compound({
        success,
        desc: buildDesc(),
        title: labels.title,
        actionLabel: buildActionLabel(),
        form: (!state ? labels.form : []),
        secondaryFormElement: renderSecondaryForm(),
        secondaryAction: (!state ? labels.secondary_action : undefined),
        onAction: (buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>, onDone?: () => void) => {
            if (state === "CONTINUE_TO_SIGN_IN") {
                navigate({
                    pathname: "/",
                    search: window.location.search,
                });
                return;
            }
            authService.signup(formValues).then(({ sanitized }: SanitizedResponse<any>) => {
                setSuccess(true);
                onDone && onDone();
                setState("CONTINUE_TO_SIGN_IN")
                Debugger.log("SignUp:onAction:success", sanitized);
            }).catch((error: SanitizedResponse<any>) => {
                setSuccess(false);
                Utils.reportError(error);
            }).finally(() => {
                buttonManageRef?.setLoadingState(false);
            });
        },
    });

    function renderSecondaryForm() {
        if (!!state) return;
        
        return (<div className="signup-secondary">
            <div className="divider"><span className="desc">{labels.or_sign_up_with}</span></div>
            <div className="social">
                <Button scheme={Schemes.RIVTN_PATULCIUS} leftIcon="fab fa-google" iconOnly textOnly rounded/>
                <Button scheme={Schemes.RIVTN_PATULCIUS} leftIcon="fab fa-facebook" iconOnly textOnly rounded/>
                <Button scheme={Schemes.RIVTN_PATULCIUS} leftIcon="fab fa-x" iconOnly textOnly rounded/>
            </div>
        </div>);
    }

    function buildDesc() {
        if (state === "CONTINUE_TO_OAUTH") {
            return labels.account_creation_success_oauth;
        } else if (state === "CONTINUE_TO_SIGN_IN") {
            return labels.account_creation_success_login;
        }
        return labels.desc;
    }

    function buildActionLabel() {
        if (state === "CONTINUE_TO_OAUTH") {
            return labels.continue_to_oauth;
        } else if (state === "CONTINUE_TO_SIGN_IN") {
            return labels.continue_to_login;
        }
        return labels.action_label;
    }

}

export default SignUp;
