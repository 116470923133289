
import { NoseurIconElement } from "@ronuse/noseur";
import en_US from "./en.json";

export interface FormInput {
    name: string;
    type?: string;
    title?: string;
    value?: string;
    invalid?: boolean;
    strengthy?: boolean;
    placeholder?: string;
    icon?: string | NoseurIconElement;
};

export interface CompoundLabels {
    desc: string;
    title: string;
    form: FormInput[];
    secondary_action: {
        location?: string;
        onAction?: () => void;
        desc?: string | React.ReactElement;
        label: string | React.ReactElement;
    };
}

export type JanusLabels = {
    logo: string;
    name: string;
    lang: string;
    rivtn: string;
    short_lang: string;
    short_name: string;
    display_name: string;
    common: {
        close: string;
        answer: string;
        continue: string;
    };
    footer: {
        label: string;
        location: string;
    }[];
    sign_in: {
        desc: string;
        title: string;
        form: FormInput[];
        action_label: string;
        stay_signed_in: string;
        forgot_password: string;
        sign_in_success: string;
        secondary_action: {
            desc: string;
            label: string;
            location: string;
        };
    };
    sign_up: {
        desc: string;
        title: string;
        form: FormInput[];
        action_label: string;
        or_sign_up_with: string;
        continue_to_login: string;
        continue_to_oauth: string;
        account_creation_success_login: string;
        account_creation_success_oauth: string;
        secondary_action: {
            desc: string;
            label: string;
            location: string;
        };
    };
    verify_email: {
        desc: string;
        title: string;
        request_new_auth: string;
        request_successful: string;
        verification_failed: string;
        verification_success: string;
        continue_to_dashboard: string;
    };
    multi_factor: {
        request_codes: string;
        factor_auth_failed: string;
        email_factor: CompoundLabels;
        phone_factor: CompoundLabels;
        multi_factor: CompoundLabels;
        authenticator: CompoundLabels;
        security_questions: CompoundLabels;
        incorrect_security_question: string;
        sending_authorization_codes: string;
        reset_actor_authenticator: {
            desc: string;
            title: string;
            form: FormInput[];
            get_auth_codes: string;
            reset_successful: string;
            phone_email_codes_required: string;
            phone_or_email_codes_invalid: string;
            request_codes_before_continue: string;
        };
    };
    reset_password: {
        desc: string;
        title: string;
        form: FormInput[];
        action_label: string;
        reset_successful: string;
        continue_to_sign_in: string;
        passwords_must_match: string;
    };
    forgot_password: {
        desc: string;
        title: string;
        form: FormInput[];
        action_label: string;
        reset_successful: string;
        secondary_action: {
            desc: string;
            label: string;
            location: string;
        };
    };
    oauth: {
        tos_detail: string;
        auth_success: string;
        privacy_policy: string;
        choose_account: string;
        terms_of_service: string;
        authorize_business: string;
        use_another_account: string;
        business_will_be_able_to: string;
    };
};

export type LanguageMap = { [key: string]: JanusLabels };

const languages: LanguageMap = {
    en_US,
};

let i18nData: JanusLabels;
let defaultLanguage: string;

export const i18nManager = {

    Labels: i18nData!,
    LabeslLoaded: false,
    ActiveLanguage: defaultLanguage!,
    Languages: [
        {
            "value": "en_US",
            "label": "English - US"
        }
    ],

    switchLanguage(lang: string) {
        localStorage.setItem("rivtn.language", lang);
        window.location.reload();
    }

};

if (!i18nManager.LabeslLoaded) {
    //localStorage.setItem("rivtn.language", "es") // uncomment to test spanish
    //localStorage.setItem("rivtn.language", "en") // uncomment to test english

    defaultLanguage = navigator.language;
    if (localStorage.getItem("rivtn.language") !== null && localStorage.getItem("rivtn.language") !== "") {
        defaultLanguage = localStorage.getItem("rivtn.language") || "en";
    }
    i18nData = languages[defaultLanguage.replace('-', '_')] || languages["en_US"];
    i18nManager.Labels = i18nData;
    i18nManager.LabeslLoaded = true;
    i18nManager.ActiveLanguage = defaultLanguage;
}