
import React from "react";
import Compound from "../Compound";
import { i18nManager } from "../../i18n";
import * as ReactRouterDom from "react-router-dom";
import { CacheManager, Debugger, Utils } from "../../utils";
import LoadingGif from "../../assets/images/loading_circle.gif";
import { AuthService, SanitizedResponse } from "../../services";

// http://localhost:3005/oauth?ref=https://suport.coparenting.com&bid=87328736762573527635762536
function Oauth() {
    const labels = i18nManager.Labels.oauth;
    const currentLocation = window.location.href;
    const navigate = ReactRouterDom.useNavigate();
    const authService = AuthService.getInstance();
    const [searchParams, _] = ReactRouterDom.useSearchParams(window.location.search);
    const referrer = searchParams.get("ref") ?? "/";
    const referrerDomain = Utils.extractDomain(referrer);
    const trustedDomains = (process.env.REACT_APP_OAUTH_TRUSTED_DOMAINS ?? "localhost:3010,rivtn.com").split(",");

    React.useEffect(() => {
        Debugger.log("Oauth:useEffect", currentLocation, trustedDomains, referrerDomain);
        if (!searchParams.has("ref") || (!trustedDomains.includes(referrerDomain) && !searchParams.has("bid"))) {
            navigate(-1);
            return;
        }
        if (!CacheManager.has(CacheManager.ACCESS_TOKEN_KEY)) {
            navigateToLogin();
            return;
        }
        fetchProfile();
    }, []);

    return Compound({
        isOauth: true,
        processing: true,
        secondaryFormElement: (<div className="load-oauth">
            <img src={LoadingGif} alt={"validating account"} />
        </div>),
    });

    function fetchProfile(refreshSession: boolean = true, config?: any) {
        authService.getProfile(config).then(({ sanitized }: SanitizedResponse<any>) => {
            Debugger.log("Oauth:fetchProfile:success", sanitized, "=>", trustedDomains, "=>", referrerDomain, "=>", trustedDomains.includes(referrerDomain));
            CacheManager.put(CacheManager.PROFILE_KEY, sanitized);
            if (trustedDomains.includes(referrerDomain)) {
                navigateForward(sanitized, {
                    name: referrer,
                }, []);
                return;
            }
            // fetch business by ID using user id, and permissions using the keys
            navigateForward(sanitized, {
                tag_line: "Ronuse Inc.",
                name: "Ronuse Community",
                external_id: searchParams.get("bid"),
                logo_location: "https://avatars.githubusercontent.com/u/69908664?s=200&v=4",
                privacy_policy: "https://avatars.githubusercontent.com/u/69908664?s=200&v=4",
                term_of_service: "https://avatars.githubusercontent.com/u/69908664?s=200&v=4",
                description: "Ronuse  community is a place where every user from various platform can interact freely. It also help manages your online profile, automate posting to your profile, get more friends and expand your network.",
            }, [
                {
                    label: "View your complete profile data",
                    info: "Get your profile information like email, full name, phone number, address"
                },
                {
                    label: "Create and manage your timeline posts",
                    info: "Create and manage your timeline posts Get your profile information like email, full name, phone number, address"
                },
                {
                    label: "Create and manage your teams",
                    info: "So we are aligned Create and manage your teams"
                },
                {
                    label: "View and manage your tournaments",
                    info: "Create and manage your timeline posts and View and manage your tournaments"
                },
            ]);
            
        }).catch((error: SanitizedResponse<any>) => {
            Debugger.log("Oauth:fetchProfile:fail", error.status, error);
            if (refreshSession) {
                authService.refreshToken({ access_token: CacheManager.get(CacheManager.ACCESS_TOKEN_KEY), session_token: CacheManager.get(CacheManager.SESSION_TOKEN_KEY) }).then(({ sanitized }: SanitizedResponse<any>) => {
                    CacheManager.insecurePut(CacheManager.ACCESS_TOKEN_KEY, sanitized.access_token);
                    Debugger.log("Oauth:fetchProfile:error:refresh:success", sanitized);
                    fetchProfile(false, {
                        headers: {
                            Authorization: `Bearer ${sanitized.access_token}`
                        }
                    });
                }).catch((error: SanitizedResponse<any>) => {
                    Debugger.log("Oauth:fetchProfile:error:refresh:error", error);
                    navigateToLogin();
                });
                return;
            }
            Utils.reportError(error);
            navigateToLogin();
        });
    }

    async function navigateToLogin() {
        navigate({
            pathname: "/",
            search: ReactRouterDom.createSearchParams({
                rloc: currentLocation,
            }).toString(),
        }, { replace: true });
    }

    async function navigateForward(profile: any, business: any, permissions: any[]) {
        navigate("/oauth/select-account", {
            state: {
                profile,
                referrer,
                business,
                permissions,
                isTrusted: trustedDomains.includes(referrerDomain),
            }
        });
    }

}

export default Oauth;
