
import React from "react";
import Compound from "../Compound";
import { i18nManager } from "../../i18n";
import * as ReactRouterDom from "react-router-dom";
import { CacheManager, Debugger, Utils } from "../../utils";
import LoadingGif from "../../assets/images/loading_circle.gif";
import { ButtonManageRef, Checkbox, NoseurObject } from "@ronuse/noseur";
import { AuthService, SanitizedResponse } from "../../services";

function VerifyEmail() {
    const navigate = ReactRouterDom.useNavigate();
    const authService = AuthService.getInstance();
    const labels = i18nManager.Labels.verify_email;
    const [success, setSuccess] = React.useState<boolean>(true);
    const [processing, setProcessing] = React.useState<boolean>();
    const [searchParams, _] = ReactRouterDom.useSearchParams(window.location.search);
    const [state, setState] = React.useState<"VERIFYING" | "SUCCESS" | "FAILED">("VERIFYING");
    const accountLocation = process.env.REACT_APP_CONSIVIUS_BASE_API_URL ?? "https://account.rivtn.com";

    React.useEffect(() => {
        if (!(searchParams.has("u") && searchParams.has("f") && searchParams.has("c"))) {
            navigate("/", { replace: true });
            return;
        }
        const verifyEmailPayload = {
            user_id: searchParams.get("u"),
            email_factor: {
                value: searchParams.get("c"),
                factor_id: searchParams.get("f"),
            }
        };
        Debugger.log("VerifyEmail:useEffect", verifyEmailPayload);
        authService.verifyEmail(verifyEmailPayload).then(({ }: SanitizedResponse<any>) => {
            setSuccess(true);
            setState("SUCCESS");
        }).catch((error: SanitizedResponse<any>) => {
            setSuccess(false);
            setState("FAILED");
            Utils.reportError(error);
        });
    }, []);

    return Compound({
        success,
        form: [],
        processing,
        desc: buildDesc(),
        title: labels.title,
        actionLabel: buildActionLabel(),
        secondaryFormElement: renderSecondaryForm(),
        onAction: (buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>) => {
            if (state === "FAILED") {
                authService.requestEmailVerification(searchParams.get("u") ?? "invalid", `${window.location.protocol}//${window.location.host}/verify-email`).then(({ }: SanitizedResponse<any>) => {
                    setSuccess(true);
                    setProcessing(true);
                    Utils.reportSuccess(labels.request_successful);
                }).catch((error: SanitizedResponse<any>) => {
                    setSuccess(false);
                    setProcessing(false);
                    Utils.reportError(error);
                }).finally(() => {
                    buttonManageRef?.setLoadingState(false);
                });
            } else if (state === "SUCCESS") {
                window.location.href = accountLocation;
            }
        },
    });

    function renderSecondaryForm() {
        if (state !== "VERIFYING") return;

        return (<div className="verify-email-secondary">
            <img src={LoadingGif} alt={labels.title}/>
        </div>);
    }

    function buildDesc() {
        if (state === "FAILED") {
            return labels.verification_failed;
        } else if (state === "SUCCESS") {
            return labels.verification_success;
        }
        return labels.desc;
    }

    function buildActionLabel() {
        if (state === "FAILED") {
            return labels.request_new_auth;
        } else if (state === "SUCCESS") {
            return labels.continue_to_dashboard;
        }
        return undefined;
    }

}

export default VerifyEmail;
