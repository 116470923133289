
import { i18nManager } from "../i18n";
import { LzEncryptor } from "./Encryptor";
import { AuthService, SanitizedResponse } from "../services";
import { MessageSchemesIcons, Scheme, Toaster } from "@ronuse/noseur";
import { CacheManager } from "./CacheManager";

export const Utils = {

    BUTTON_LOADING_PROPS: {
        disabled: true,
        leftIcon: "fas fa-spinner fa-pulse"
    },

    COMPOSED_PASSWORD_PROGRESS_PROPS: {
        percentageColors: {
            0: {
                fg: "#EB5959",
            },
            25: {
                fg: "#FFDD85",
            },
            80: {
                fg: "#1BA6C5",
            },
            90: {
                fg: "#0FA883",
            },
        }
    },

    CIRCLE: (<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="6.33369" cy="6.5" rx="6.33369" ry="6.5" fill="#244147" />
    </svg>
    ),

    collapseString: (value: string, max: number, contd: string = "...") => {
        let collapsedBack = "";
        let collapsedFront = "";
        let halfMax = Math.trunc(max / 2);
        if (!value || value?.length < max) return value;
        for (let index = 0; index < halfMax; index++) {
            collapsedFront += value[index];
            collapsedBack += value[value?.length - halfMax + index];
        }
        return collapsedFront + contd + collapsedBack;
    },

    report: (content: string, scheme: Scheme = Scheme.DANGER, icon: MessageSchemesIcons = MessageSchemesIcons.INFO, lifetime: number = 5000) => {
        Toaster.toast({
            icon,
            scheme,
            content,
            lifetime,
            foreScheme: true,
            showProgressbar: true,
            pauseDelayOnHover: true,
            style: { background: "white" },
        });
    },

    reportSuccess: (content: string, lifetime: number = 5000) => {
        Utils.report(content, Scheme.SUCCESS, MessageSchemesIcons.SUCCESS, lifetime);
    },

    reportError: (response: SanitizedResponse<any> | string) => {
        Utils.report(((typeof response === "string") ? response : (response?.errorMessage ?? `${response}`)));
    },

    redirectToReferrer: async (url: string, sessionToken: string, accessToken: string, isOauth: boolean = true) => {
        if (isOauth && !((url.includes(window.location.host) || url.startsWith("/")) && url.includes("/oauth"))) {
            url = `/oauth?ref=${url}`;
        }
        if (isOauth) {
            Utils.reportSuccess(i18nManager.Labels.sign_in.sign_in_success);
        } else {
            Utils.reportSuccess(i18nManager.Labels.oauth.auth_success.replace("${location}", url));
        }
        CacheManager.insecurePut(CacheManager.ACCESS_TOKEN_KEY, accessToken);
        CacheManager.put(CacheManager.SESSION_TOKEN_KEY, sessionToken);
        if (isOauth) {
            window.location.href = url;
            return;
        }
        const separator = url?.includes("?") ? "&" : "?";
        const auth: any = {
            a: accessToken,
            s: sessionToken,
            p: CacheManager.get(CacheManager.PROFILE_KEY),
        }
        window.location.href = url + separator + `pas=${encodeURIComponent(LzEncryptor.encrypt(JSON.stringify(auth), true))}`;
    },

    extractDomain: (url: string) => {
        let domain = url;
        if (domain.startsWith("http://")) domain = domain.substring(7);
        else if (domain.startsWith("https://")) domain = domain.substring(8);
        const slashIndex = domain.indexOf("/");
        domain = domain.substring(0, slashIndex > -1 ? slashIndex : domain.length);
        let dotIndex = domain.lastIndexOf(".");
        if (dotIndex > -1) {
            domain = domain.substring(domain.substring(0, dotIndex).lastIndexOf(".") + 1);
        }
        return domain.trim();
    },

}

export const Schemes: {
    RIVTN_PATULCIUS: Scheme;
} = {
    RIVTN_PATULCIUS: Scheme.DARK
}

export * from "./Debugger";
export * from "./Encryptor";
export * from "./CacheManager";