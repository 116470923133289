
import React from "react";
import Compound from "../Compound";
import { Utils } from "../../utils";
import { i18nManager } from "../../i18n";
import * as ReactRouterDom from "react-router-dom";
import { ButtonManageRef, NoseurObject } from "@ronuse/noseur";
import { AuthService, SanitizedResponse } from "../../services";

function ForgotPassword() {
    const authService = AuthService.getInstance();
    const navigate = ReactRouterDom.useNavigate();
    const labels = i18nManager.Labels.forgot_password;
    const [success, setSuccess] = React.useState<boolean>();
    const [processing, setProcessing] = React.useState<boolean>();

    return Compound({
        success,
        processing,
        form: labels.form,
        desc: labels.desc,
        title: labels.title,
        actionLabel: labels.action_label,
        secondaryAction: labels.secondary_action,
        onInput: (_: string, _v: any) => setSuccess(undefined),
        onAction: (buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>, onDone?: () => void) => {
            authService.sendPasswordResetEmail({ prefix_url: `${window.location.protocol}//${window.location.host}/reset-password`, email: formValues.email }).then(({ }: SanitizedResponse<any>) => {
                setSuccess(true);
                onDone && onDone();
                setProcessing(true);
                setTimeout(() => navigate("/"), 3000);
                Utils.reportSuccess(labels.reset_successful, 60000);
            }).catch((error: SanitizedResponse<any>) => {
                setSuccess(false);
                Utils.reportError(error);
            }).finally(() => {
                buttonManageRef?.setLoadingState(false);
            });
        },
    });

}

export default ForgotPassword;
