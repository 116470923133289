
import React from "react";
import Compound from "../Compound";
import { i18nManager } from "../../i18n";
import { NavLink } from "react-router-dom";
import { FactorState } from "./MultiFactor";
import * as ReactRouterDom from "react-router-dom";
import { Schemes, Debugger, Utils } from "../../utils";
import { AuthService, SanitizedResponse } from "../../services";
import { ButtonManageRef, Checkbox, NoseurObject } from "@ronuse/noseur";

function SignIn() {
    const labels = i18nManager.Labels.sign_in;
    const navigate = ReactRouterDom.useNavigate();
    const authService = AuthService.getInstance();
    const [success, setSuccess] = React.useState<boolean>();
    const [staySignedIn, setStaySignedIn] = React.useState<boolean>();
    const [searchParams, _] = ReactRouterDom.useSearchParams(window.location.search);
    const redirectLocation = searchParams.get("rloc") ?? process.env.REACT_APP_CONSIVIUS_BASE_API_URL ?? "https://account.rivtn.com";

    return Compound({
        success,
        form: labels.form,
        desc: labels.desc,
        title: labels.title,
        actionLabel: labels.action_label,
        secondaryAction: labels.secondary_action,
        secondaryFormElement: renderSecondaryForm(),
        onInput: (_: string, _v: any) => setSuccess(undefined),
        onAction: (buttonManageRef: ButtonManageRef | null, formValues: NoseurObject<any>, onDone?: () => void) => {
            formValues["token_expiry_in_minutes"] = 60;
            formValues["session_expiry_in_days"] = (staySignedIn ? 90 : 1);
            authService.signin(formValues).then(({ sanitized }: SanitizedResponse<any>) => {
                setSuccess(true);
                onDone && onDone();
                navigateForward(sanitized);
                Debugger.log("SignIn:onAction:success", sanitized);
            }).catch((error: SanitizedResponse<any>) => {
                setSuccess(false);
                Utils.reportError(error);
                buttonManageRef?.setLoadingState(false);
            });
        }
    });

    function renderSecondaryForm() {
        return (<div className="signin-secondary">
            <Checkbox scheme={Schemes.RIVTN_PATULCIUS} label={labels.stay_signed_in} onChange={(e: any) => setStaySignedIn(e.checked)} />
            <NavLink to={"/forgot-password"}>{labels.forgot_password}</NavLink>
        </div>);
    }

    async function navigateForward(response: any) {
        const isOauth = searchParams.has("rloc");
        if (!Object.keys(response.required_fa).length) {
            Utils.redirectToReferrer(redirectLocation, response.session_token, response.access_token, isOauth);
            return;
        }
        let factorState = FactorState.EMAIL_FACTOR;
        if (response.required_fa.email && response.required_fa.phone) {
            factorState = FactorState.MULTI_FACTOR;
        } else if (response.required_fa.phone) {
            factorState = FactorState.PHONE_FACTOR;
        } else if (response.required_fa.security_questions) {
            factorState = FactorState.SECURITY_QUESTIONS;
        } else if (response.required_fa.actor_authenticator) {
            factorState = FactorState.AUTHENTICATOR;
        }
        navigate("/fa", {
            state: {
                isOauth,
                response,
                factorState,
                redirectLocation,
            }
        });
    }

}

export default SignIn;
